<template>
  <div class="block-detial-box">
    <el-card class="box-card" style="margin-bottom: 20px">
      <div slot="header">
        <div class="card-header">
          {{$t('index.地址')}} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{{address}}  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <span v-if="!!token">{{token.totalSupply}}{{token.symbol}}   &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  {{token.decimals}}</span>
        </div>
      </div>
      <el-divider></el-divider>
      <div class="balance-box">
          {{ web3.utils.fromWei(balance || "0", "ether") }} ds
      </div>
    </el-card>
    <el-card class="box-card">
      <div slot="header">
        <div class="card-header">
          {{$t('index.交易记录')}}
        </div>
      </div>
       <el-divider></el-divider>
    <el-table :data="transactionList" style="width: 100%">
      <el-table-column :label="$t('transaction.交易哈希')">
        <template #default="scope">
            <router-link class="text-ellipsis" :to="{ name: 'TransactionDetial', params: { hash: scope.row.hash }}">{{ scope.row.hash }}</router-link>
          </template>
      </el-table-column>
      <el-table-column
        width="100px"
        :label="$t('block.区块')"
      >
        <template #default="scope">
                <router-link :to="{name: 'BlockDetial', params: { blockNumber: scope.row.blockNumber }}">{{ scope.row.blockNumber }}</router-link>
              </template>
      </el-table-column>
      <el-table-column :label="$t('transaction.发送方')">
       <template #default="scope">
                <router-link class="text-ellipsis" :to="{name: 'AddressDetial', params: { address: scope.row.fromAddress }}">{{ scope.row.fromAddress }}</router-link>
              </template>
      </el-table-column>
      <el-table-column :label="$t('transaction.接收方')">
        <template #default="scope">
                <router-link v-if="scope.row.toAddress" class="text-ellipsis" :to="{name: 'AddressDetial', params: { address: scope.row.toAddress }}">{{ scope.row.toAddress }}</router-link>
                <span class="text-ellipsis" v-else>{{$t('transaction.创建合约')}}</span>
              </template>
      </el-table-column>
      <el-table-column :label="$t('transaction.价值')">
        <template #default="scope">
          <span class="text-ellipsis"
            >{{ web3.utils.fromWei(scope.row.ethValue, "ether") }} ds</span
          >
        </template>
      </el-table-column>
      <el-table-column :label="$t('transaction.交易费用')">
        <template #default="scope">
          <span class="text-ellipsis"
            >{{ web3.utils.fromWei(scope.row.gasPrice, "Gwei") }} Gwei</span
          >
        </template>
      </el-table-column>
      <el-table-column :label="$t('transaction.时间')">
        <template #default="scope">
          <span class="text-ellipsis">{{
            dateFormat(
              "yyyy-MM-dd hh:mm:ss",
              new Date(scope.row.timestamp * 1000)
            )
          }}</span>
        </template>
      </el-table-column>
    </el-table>
    </el-card>
       <div class="page-pagination-box">
      <el-pagination
        background
        :page-size="limit"
        layout="prev, pager, next"
        @current-change="currentChange"
        :current-page="Number(page)"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, getCurrentInstance } from "vue";
import { useRouter, useRoute, onBeforeRouteUpdate } from "vue-router";
import { getBlance, getTransactionByAddress } from "@/api";
import abi from "@/utils/abi.json"
import { useI18n } from "vue-i18n"
import Web3 from "web3"
export default {
  components: {},
  setup() {
    const router = useRouter();
    const { t } = useI18n()
    const route = useRoute();
    const data = reactive({
      $t: t,
      address: route.params.address,
      balance: '0',
      isContract: false,
      page: route.query.page || 1,
      limit: 30,
      total: 0,
      transactionList: [],
      token: {
        symbol: '',
        decimals: '',
        totalSupply: ''
      },
      currentChange: number => {
        router.replace({ name: "AddressDetial", query: { page: number } });
      },
      toDetail: (row, column, event) => {
        router.push({ name: "TransactionDetial", params: { hash: row.hash } });
      }
    });
    onMounted(() => {
      getBlance({address: data.address}).then((res) => {
         data.balance = res.data
         data.token = res.token
      })
      getTransactionByAddress({limit: data.limit, page: data.page, address: data.address}).then((res) => {
        data.transactionList = res.data.list;
        data.total = res.data.total;
      })
    });
    onBeforeRouteUpdate((to, from) => {
      data.address = to.params.address
      if (from.name == 'AddressDetial') {
        getBlance({address: data.address}).then((res) => {
          data.balance = res.data
          data.token = res.token
        })  
      }
      data.page = to.query.page || 1;
      getTransactionByAddress({limit: data.limit, page: data.page, address: data.address}).then((res) => {
        data.transactionList = res.data.list;
        data.total = res.data.total;
      })
    });
    const refData = toRefs(data);
    return {
      ...refData
    };
  }
};
</script>

<style lang="scss" scoped>
.card-header {
  font-size: 18px;
  font-weight: 700;
  text-align: left;
}
.item {
  font-size: 16px;
  color: #333;
  text-align: left;
  .title {
    width: 20%;
  }
  .value {
    flex: 1;
  }
}
.page-pagination-box {
  text-align: right;
  margin-top: 30px;
}
.balance-box{
  font-size: 20px;
  font-weight: 700;
  margin-top: 20px;
}
</style>
